enum RoutesEnum {
  MAIN = '/',
  BLOG = '/blog',
  MORTGAGE_PRODUCTS_SERVICES = '/mortgage-products-services',
  LOGIN = '/login',
  LOGOUT = '/logout',
  DASHBOARD = '/dashboard',
  THANK_YOU = '/thank-you',
  PROFILE = '/profile',
  AGENT = '/agent',
  MORTGAGE_CALCULATOR = '/mortgage-calculator',
  ABOUT_US = '/about-us',
  PRIVACY_POLICY = '/privacy-policy',
  RESOURCES = '/resources',
  PARTNER = '/partner',
  MORTGAGE_FAQ = '/mortgage-faq',
  UK_MORTGAGES = '/expat-mortgages-uk',
  COMMERCIAL_REAL_ESTATE = '/commercial-mortgage-real-estate-loans',
  CONVEYANCING = '/conveyancing-power-of-attorney-services',
  CONCIERGE_REAL_ESTATE_SERVICES = '/concierge-real-estate-services',
  NEW_DASHBOARD = '/dashboard',
  PRODUCTS = '/dashboard/products',
  COMPARE_PRODUCTS = '/dashboard/products/compare',
  PERSONAL_INFORMATION = '/dashboard/personal-information',
  PRODUCT_REQUIREMENT = '/dashboard/product-requirement',
  UPLOAD_DOCUMENT = '/dashboard/upload-document',
  SET_PASSWORD = '/auth/set-password',
  URL = '/url',
}

export default RoutesEnum;
