import type { MouseEventHandler } from 'react';
import React from 'react';
import { cn } from '@/src/util/tailwind';
import HoloLocalLoader from '../loader/HoloLocalLoader';

const sizeClasses = {
  sm: 'px-4 py-[7px] rounded-[30px] text-sm font-medium ',
  default: 'px-[24px] h-[48px] rounded-[30px] font-semibold ',
  lg: 'px-12 h-[56px] rounded-[30px] font-semibold ',
};
const themeClasses = {
  primary:
    'bg-primary text-white hover:outline hover:outline-accent disabled:hover:outline-none',
  white:
    'bg-white text-black border border-natural-gray-2 hover:bg-natural-gray-2',
};

type HoloButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  text?: string;
  isLoading?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
  size: keyof typeof sizeClasses;
  theme: keyof typeof themeClasses;
  extraClasses?: string;
  btnType?: 'button' | 'submit' | 'reset';
  children?: React.ReactNode;
};

const HoloButton = (props: HoloButtonProps) => {
  const {
    text,
    onClick,
    size,
    theme,
    btnType,
    extraClasses,
    children,
    disabled,
    isLoading,
    ...buttonProps
  } = props;

  return (
    <button
      onClick={onClick}
      type={btnType}
      disabled={disabled}
      className={cn(sizeClasses[size], themeClasses[theme], extraClasses, {
        'cursor-not-allowed opacity-40 hover:outline-0': disabled,
      })}
      {...buttonProps}
    >
      {isLoading ? (
        <HoloLocalLoader height="1rem" width="1rem" />
      ) : (
        <span>{children ? children : text}</span>
      )}
    </button>
  );
};

export default HoloButton;
