import React from 'react';
import Image from 'next/image';
import dynamic from 'next/dynamic';
import { i18n, useTranslation } from 'next-i18next';
import Link from 'next/link';
import HoloText from '@/components/common/Typography/HoloText';
import HoloButton from '@/components/common/Button/HoloButton';
import {
  companyNavItems,
  partnerNavItems,
  resourcesNavItems,
} from '@/components/navbar/navbarLists';
import FacebookIcon from '@/src/assets/social/facebook.svg';
import InstagramIcon from '@/src/assets/social/ig.svg';
import TwitterIcon from '@/src/assets/social/twitter.svg';
import LinkedinIcon from '@/src/assets/social/linkedin.svg';
import YoutubeIcon from '@/src/assets/social/youtube.svg';
import RoutesEnum from '@/src/enums/routes.enum';
import { isLoggedInAdmin } from '@/src/util/auth';
import { DirUtils } from '@/src/util/dir';
import ClientOnlyComponent from '@/components/ClientOnlyComponent';

const links = {
  Company: companyNavItems,
  Resources: resourcesNavItems,
  Partners: partnerNavItems,
};
const socialLinks = {
  facebook: {
    link: 'https://www.facebook.com/useholo/',
    icon: FacebookIcon,
  },
  youtube: {
    link: 'https://www.youtube.com/channel/UCHwXnCCGDUK5Bi_E2UxEgVQ',
    icon: YoutubeIcon,
  },
  twitter: {
    link: 'https://twitter.com/HoloUae?s=20&t=49e8JKRdhSMY5dRY2kxVXQ',
    icon: TwitterIcon,
  },
  instagram: {
    link: 'https://www.instagram.com/useholo',
    icon: InstagramIcon,
  },
  linkedin: {
    link: 'https://www.linkedin.com/company/holo-mortgage-consultants/',
    icon: LinkedinIcon,
  },
};

//remove display after A/B testing
type AppFooterProps = {
  display?: 'none' | 'block';
};

const DefaultAppFooter = (props: AppFooterProps) => {
  const { display = 'block' } = props;

  const isLoggedIn = isLoggedInAdmin();
  const { t } = useTranslation('app-common');
  const isRtl = DirUtils.isRtl(i18n?.language);

  function getCurrentYear(isRTL) {
    const locale = isRTL ? 'ar-EG' : 'en';
    return new Intl.DateTimeFormat(locale, { year: 'numeric' }).format(
      new Date(),
    );
  }

  return (
    <footer style={{ display }} className="bg-black">
      <div className="m-auto  max-w-6xl ">
        <div className="flex w-full max-w-6xl flex-col gap-12  px-6 py-6 pt-12 md:flex-row md:gap-[92px] md:py-12 xl:px-[0px]">
          <div className="flex flex-1 flex-col items-start gap-6">
            <Image width={70} height={30} alt="logo" src="/assets/logo.svg" />
            <HoloText
              extraClasses="text-natural-gray-2"
              text={t('FooterMsg')}
              tag="p"
              flavour="regular"
            />
            <ClientOnlyComponent>
              <Link
                href={
                  isLoggedIn
                    ? RoutesEnum.DASHBOARD
                    : RoutesEnum.MORTGAGE_PRODUCTS_SERVICES
                }
              >
                <HoloButton
                  text={isLoggedIn ? t('Dashboard') : t('GetStarted')}
                  size="sm"
                  theme="white"
                  extraClasses={`${
                    !isLoggedIn
                      ? DirUtils.getLang(i18n?.language) + '-get-started-footer'
                      : ''
                  }`}
                />
              </Link>
            </ClientOnlyComponent>
          </div>
          <div className=" flex flex-wrap gap-6 md:flex-[2] md:flex-row  md:justify-between">
            {Object.keys(links).map((entity) => {
              return (
                <div key={entity} className="flex flex-1 flex-col gap-6">
                  <HoloText
                    extraClasses="text-natural-darkGray-2"
                    text={t(entity)}
                    tag="p"
                    flavour="regular"
                  />
                  <div className="flex flex-col gap-3">
                    {links[entity].map((e) => {
                      return (
                        <Link key={entity + '' + e.href} href={e.href}>
                          <span className="cursor-pointer whitespace-pre text-xs font-medium text-white hover:underline hover:underline-offset-8 md:text-sm">
                            {t(e.label)}
                          </span>
                        </Link>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="h-[1px] w-full bg-natural-darkGray-1"></div>
        <div className="flex flex-col-reverse items-center justify-between gap-6 p-6 md:flex-row xl:px-[0px]">
          <HoloText
            extraClasses="text-natural-darkGray-2"
            tag="p"
            flavour="regular"
            text={`@ ${getCurrentYear(isRtl)} ${t('copyright')}`}
          />

          <div className="flex gap-6">
            {Object.keys(socialLinks).map((e: keyof typeof socialLinks) => {
              const el = socialLinks[e];
              return (
                <Link key={e} href={el.link} target="_blank">
                  <Image src={el.icon} alt={e} />
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    </footer>
  );
};
const AppFooter = dynamic(() => Promise.resolve(DefaultAppFooter), {
  ssr: false,
});

export default AppFooter;
