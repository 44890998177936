import React from 'react';
import Router from 'next/router';
import { parseJwt } from '@/src/util/axios';
import RoutesEnum from '@/src/enums/routes.enum';

export const logout = () => {
  if (typeof window !== 'undefined') {
    localStorage.removeItem('holo_token');
    // To trigger the event listener we save some random data into the `logout` key
    window.localStorage.setItem('logout', Date.now().toString());
  }
};

export const logoutAdmin = () => {
  if (typeof window !== 'undefined') {
    localStorage.removeItem('holo_token');
    // To trigger the event listener we save some random data into the `logout` key
    window.localStorage.setItem('logout', Date.now().toString());
  }
};

export const loginAdmin = (token: any) => {
  if (typeof window !== 'undefined') {
    localStorage.setItem('holo_token', token);
  }
};

export const isLoggedInAdmin = () => {
  if (typeof window !== 'undefined') {
    const token = localStorage.getItem('holo_token');
    // check if jwt token is expired
    if (token) {
      const { exp } = parseJwt(token);
      if (Date.now() >= exp * 1000) {
        return false;
      }
    }
    return token !== undefined && token !== null && token !== '';
  }
  return false;
};

export const auth = (ctx: any) => {
  if (typeof window !== 'undefined') {
    const token = localStorage.getItem('holo_token');

    if (ctx.req && !token) {
      ctx.res.writeHead(302, { Location: RoutesEnum.LOGIN });
      ctx.res.end();
      return;
    }

    if (!token) {
      Router.push(RoutesEnum.LOGIN);
    }

    return token;
  }
};

export const withAdminAuthSync = (
  WrappedComponent: any,
  requireAuth = true,
) => {
  const Wrapper = (props: any) => <WrappedComponent {...props} />;

  Wrapper.getInitialProps = async (ctx: any) => {
    if (typeof window !== 'undefined') {
      const holo_token = localStorage.getItem('holo_token');
      const isLogged = isLoggedInAdmin();

      if (requireAuth && !isLogged) {
        if (ctx.req) {
          ctx.res.writeHead(302, { Location: RoutesEnum.LOGIN });
          ctx.res.end();
          return; // should be added IMO.
        } else {
          await Router.push(RoutesEnum.LOGIN);
        }
      }

      const componentProps =
        WrappedComponent.getInitialProps &&
        (await WrappedComponent.getInitialProps(ctx));
      return { ...componentProps, token: holo_token, isLogged: isLogged };
    }
    return {};
  };

  return Wrapper;
};
