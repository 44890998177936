import React from 'react';

type HoloIconProps = {
  id: string;
  className?: string;
};

const HoloIcon = (props: HoloIconProps) => {
  const { id } = props;
  return (
    <svg {...props} id={id}>
      <use href={`/assets/sprite.svg#${id}`} />
    </svg>
  );
};

export default HoloIcon;
