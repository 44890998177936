import React from 'react';
type ClientOnlyComponentProps = {
  children: React.ReactNode;
};

const ClientOnlyComponent = (props: ClientOnlyComponentProps) => {
  const [isMounted, setIsMounted] = React.useState(false);
  React.useEffect(() => {
    setIsMounted(true);
  }, []);
  if (!isMounted) return null;

  return <>{props.children}</>;
};

export default ClientOnlyComponent;
