import React from 'react';

import Style from './loader.module.css';
type HoloLocalLoaderProps = {
  height?: string;
  width?: string;
};

const HoloLocalLoader = (props: HoloLocalLoaderProps) => {
  const { width, height } = props;
  return (
    <div className="flex h-full w-full items-center justify-center">
      <div
        className={Style.loader}
        style={{
          width,
          height,
        }}
      ></div>
    </div>
  );
};

export default HoloLocalLoader;
