export class DirUtils {
  private static defaultLang = 'default';

  static isRtl(lang = this.defaultLang) {
    return lang === 'ar';
  }

  static isLtr(lang = this.defaultLang) {
    return ['default', 'en'].includes(lang);
  }

  static getLang(lang = this.defaultLang) {
    return lang === 'default' ? 'en' : lang;
  }
}
