import RoutesEnum from '@/src/enums/routes.enum';
export interface NavItem {
  label: string;
  subLabel?: string;
  children?: Array<NavItem>;
  href?: string;
  itemType?: string;
  buttonColor?: string;
}

export const NAV_ITEMS: Array<NavItem> = [
  { label: 'Login', itemType: 'loginProfile' },
  { label: 'Login', itemType: 'loginProfile' },
];

export const DashboardNavList: Array<NavItem> = [
  { label: 'Login', itemType: 'loginProfile' },
];

export const MobileDashboardNavList: Array<NavItem> = [
  { label: 'Profile', href: RoutesEnum.PROFILE, itemType: 'getMortgage' },
  { label: 'Logout', href: RoutesEnum.LOGOUT },
];

export const ProfileNavList: Array<NavItem> = [
  { label: 'Login', itemType: 'loginProfile', buttonColor: 'black' },
];

export const MobileProfileNavList: Array<NavItem> = [
  { label: 'Profile', href: RoutesEnum.PROFILE },
  { label: 'Dashboard', href: RoutesEnum.DASHBOARD },
  { label: 'Logout', href: RoutesEnum.LOGOUT },
];

export const AboutUsNavList: Array<NavItem> = [
  {
    label: 'GetStarted',
    href: RoutesEnum.MORTGAGE_PRODUCTS_SERVICES,
    itemType: 'getMortgage',
  },
];
// to be removed when we merged the new navigation component
export const HomePageNavList: Array<NavItem> = [
  { label: 'AboutUs', href: RoutesEnum.ABOUT_US, itemType: 'text' },
  { label: 'FAQs', href: RoutesEnum.MORTGAGE_FAQ, itemType: 'text' },
  {
    label: 'Calculator',
    href: RoutesEnum.MORTGAGE_CALCULATOR,
    itemType: 'text',
  },
  { label: 'Login', href: RoutesEnum.LOGIN, itemType: 'login' },
  { label: 'Dashboard', href: RoutesEnum.DASHBOARD },
  {
    label: 'GetStarted',
    href: RoutesEnum.MORTGAGE_PRODUCTS_SERVICES,
    itemType: 'getMortgage',
  },
];
// new stuff
export const LandingPageNavItems: Array<NavItem> = [
  {
    label: 'mortgage',
    href: RoutesEnum.MORTGAGE_PRODUCTS_SERVICES,
    itemType: 'getMortgage',
  },
  {
    label: 'MortgageCalculator',
    href: RoutesEnum.MORTGAGE_CALCULATOR,
    itemType: 'text',
  },
  { label: 'BecomeAPartner', href: RoutesEnum.PARTNER, itemType: 'text' },
  { label: 'AboutUs', href: RoutesEnum.ABOUT_US, itemType: 'text' },
  { label: 'Blog', href: RoutesEnum.BLOG, itemType: 'text' },
  { label: 'Resources', href: RoutesEnum.RESOURCES, itemType: 'text' },
  { label: 'FAQs', href: RoutesEnum.MORTGAGE_FAQ, itemType: 'text' },
];

export const LandingPageNavItemsMobile: Array<NavItem> = [
  { label: 'profile', href: RoutesEnum.PROFILE, itemType: 'getMortgage' },
  { label: 'Dashboard', href: RoutesEnum.DASHBOARD, itemType: 'getMortgage' },
  {
    label: 'mortgage',
    href: RoutesEnum.MORTGAGE_PRODUCTS_SERVICES,
    itemType: 'getMortgage',
  },
  {
    label: 'MortgageCalculator',
    href: RoutesEnum.MORTGAGE_CALCULATOR,
    itemType: 'text',
  },
  { label: 'BecomeAPartner', href: RoutesEnum.PARTNER, itemType: 'text' },
  { label: 'AboutUs', href: RoutesEnum.ABOUT_US, itemType: 'text' },
  { label: 'Blog', href: RoutesEnum.BLOG, itemType: 'text' },
  { label: 'Resources', href: RoutesEnum.RESOURCES, itemType: 'text' },
  { label: 'FAQs', href: RoutesEnum.MORTGAGE_FAQ, itemType: 'text' },
];

export const companyNavItems = [
  { label: 'AboutUs', href: RoutesEnum.ABOUT_US, itemType: 'text' },
  { label: 'FAQs', href: RoutesEnum.MORTGAGE_FAQ, itemType: 'text' },
  { label: 'Tips & Trends', href: RoutesEnum.RESOURCES, itemType: 'text' },
  {
    label: 'Privacy & Policy',
    href: RoutesEnum.PRIVACY_POLICY,
    itemType: 'text',
  },
];

export const resourcesNavItems = [
  {
    label: 'HoloConcierge',
    href: RoutesEnum.CONCIERGE_REAL_ESTATE_SERVICES,
    itemType: 'text',
  },
  {
    label: 'MortgageCalculator',
    href: RoutesEnum.MORTGAGE_CALCULATOR,
    itemType: 'text',
  },
  { label: 'Blog', href: RoutesEnum.BLOG, itemType: 'text' },
  { label: 'UKMortgage', href: RoutesEnum.UK_MORTGAGES, itemType: 'text' },
  {
    label: 'CommercialRealEstate',
    href: RoutesEnum.COMMERCIAL_REAL_ESTATE,
    itemType: 'text',
  },
  {
    label: 'Conveyancing',
    href: RoutesEnum.CONVEYANCING,
    itemType: 'text',
  },
];

// partner
export const partnerNavItems = [
  {
    label: 'BecomeAPartner',
    href: RoutesEnum.PARTNER,
    itemType: 'text',
  },
  {
    label: 'CurrencyTransfer',
    href: 'https://uae.ifxpayments.com/holo-partnership/?ppid=65751-SD',
    itemType: 'text',
  },
];
