import { cn } from '@/src/util/tailwind';

const typoGraphyClasses = {
  p: 'font-semibold text-base leading-[22.4px]',
  h1: 'text-[48px] leading-[45px] font-semibold',
  h2: 'text-[36px] leading-[45px] font-semibold',
  h3: 'font-semibold text-[28px] leading-[35px]',
  h5: 'text-[18px] leading-[22.5px] font-semibold',
  h6: 'text-base leading-[20px] font-semibold',
  large_semiBold: 'text-[20px] leading-[28px] font-semibold',
  large_regular: 'text-[20px] leading-[28px]',
  medium: 'text-base leading-[22.4px] font-medium',
  regular: 'text-[14px] leading-[19.6px] font-normal',
};

type TextProps = {
  text: string;
  extraClasses?: string;
  tag: 'span' | 'p' | 'h1' | 'h3' | 'h2' | 'h6' | 'h5';
  flavour?: keyof typeof typoGraphyClasses;
};

const HoloText = (props: TextProps) => {
  const { extraClasses, text, tag: Tag, flavour } = props;
  const selectedClass = flavour ?? Tag;

  return (
    <Tag className={cn(typoGraphyClasses[selectedClass], extraClasses)}>
      {text}
    </Tag>
  );
};

export default HoloText;
